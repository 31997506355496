import moment from 'moment';

const sessionsDateFormat = dates => {
  return dates
    ?.filter(e => e.sessionDate)
    ?.map(e => moment(e.sessionDate).format('DD MMM YYYY'))
    ?.join(', ');
};

export const sessionsTimeFormat = dates => {
  return dates
    ?.filter(e => e.startTime || e.endTime)
    ?.map(e => `${e.startTime || 'N/A'} to ${e.endTime || 'N/A'}`)
    ?.join(', ');
};

export default sessionsDateFormat;
